import { render, staticRenderFns } from "./About.vue?vue&type=template&id=5b9797fc&scoped=true"
import script from "./About.vue?vue&type=script&lang=js"
export * from "./About.vue?vue&type=script&lang=js"
import style0 from "./About.vue?vue&type=style&index=0&id=5b9797fc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b9797fc",
  null
  
)

export default component.exports