<template>
  <header class="header">
    <div class="logo">浙江盛世文脉科技有限公司</div>
    <nav class="nav">
      <ul>
        <li><router-link to="/" exact-active-class="active">首页</router-link></li>
        <li><router-link to="/services" exact-active-class="active">产品与服务</router-link></li>
        <li><router-link to="/team" exact-active-class="active">团队与部门</router-link></li>
        <li><router-link to="/contact" exact-active-class="active">联系我们</router-link></li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: "HeaderComponent",
};
</script>

<style scoped>
.header {
  background: linear-gradient(135deg, #4e54c8, #8f94fb);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  position: relative;
}

.header:hover {
  transform: translateY(-2px);
}

.logo {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 1px;
}

.nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav ul li {
  margin: 0 15px;
  position: relative;
}

.nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  padding: 5px 10px;
  transition: color 0.3s ease;
}

.nav ul li a:hover {
  color: #ffeb3b; /* 鼠标悬停时的颜色 */
}

.nav ul li a.active {
  background-color: #ffdd57; /* 激活链接的背景色 */
  color: #2c3e50; /* 激活链接的字体颜色 */
  border-radius: 4px; /* 激活链接的圆角效果 */
}

.nav ul li::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -5px;
  height: 2px;
  width: 0;
  background: #ffeb3b; /* 动画的底部线条颜色 */
  transition: width 0.3s ease, left 0.3s ease;
}

.nav ul li:hover::after {
  width: 100%;
  left: 0;
}
</style>
