<template>
  <section class="about-section">
    <div class="container">
      <h2 class="about-title">关于我们</h2>
      <p class="about-description">
        我们公司致力于提供卓越的服务和产品。凭借一支充满激情的专业团队，我们提供能够带来价值的创新解决方案。
      </p>
      <div class="about-scope">
        <h3>经营范围</h3>
        <div class="scope-modules">
          <div class="scope-module" v-for="(module, index) in modules" :key="index">
            <h4>{{ module.title }}</h4>
            <p>{{ module.content }}</p>
          </div>
        </div>
      </div>
      <div class="about-cards">
        <div class="card" v-for="(item, index) in cards" :key="index">
          <h3>{{ item.title }}</h3>
          <p>{{ item.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      modules: [
        {
          title: "技术服务",
          content: "技术开发、技术咨询、技术交流、技术转让、技术推广，数字技术服务，数据处理和存储支持服务。"
        },
        {
          title: "互联网和数字服务",
          content: "互联网销售，数字内容制作，广告设计、代理及发布，数字文化创意产品展览展示服务，动漫游戏开发。"
        },
        {
          title: "人工智能与大数据",
          content: "人工智能基础资源与技术平台、基础软件和应用软件开发，行业应用系统集成服务，大数据服务，物联网数据服务。"
        },
        {
          title: "设计与软件开发",
          content: "软件开发，信息系统集成服务，专业设计服务，信息系统运行维护服务。"
        },
        {
          title: "设备制造与销售",
          content: "文化场馆用智能设备、环境监测仪器、信息安全设备、虚拟现实设备制造及销售。"
        },
        {
          title: "3D打印与工程服务",
          content: "3D打印服务，机械设备研发与销售，工程管理服务。"
        },
        {
          title: "教育和文化活动",
          content: "教育咨询服务，组织文化艺术交流活动，娱乐性展览，会议及展览服务。"
        },
      ],
      cards: [
        { title: "我们的使命", description: "致力于创新并在每一项服务中追求卓越。" },
        { title: "我们的愿景", description: "成为领先的优质解决方案提供商。" },
        { title: "我们的价值观", description: "诚信、激情、卓越。" },
      ],
    };
  },
};
</script>

<style scoped>
.about-section {
  padding: 80px 20px;
  background: linear-gradient(135deg, #2c3e50, #34495e); /* 深蓝色渐变背景，传递传统文化与科技感 */
  color: #ecf0f1; /* 浅灰色字体，易读性强 */
  text-align: center;
}

.about-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #f39c12; /* 橙色标题，突显活力 */
}

.about-description {
  font-size: 1.2rem;
  margin-bottom: 40px;
  color: #bdc3c7; /* 浅灰色，保持视觉平衡 */
}

.about-scope {
  margin: 20px 0;
  padding: 30px;
  background: rgba(44, 62, 80, 0.7); /* 深色半透明背景，突出内容 */
  border-radius: 12px;
}

.scope-modules {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.scope-module {
  flex: 1 1 300px;
  min-width: 250px;
  max-width: 350px;
  background: rgba(255, 255, 255, 0.1); /* 半透明白色背景，增加层次感 */
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* 加强阴影效果 */
  transition: transform 0.3s;
}

.scope-module h4 {
  font-size: 1.2rem;
  color: #f39c12; /* 橙色，用于强调模块标题 */
  margin-bottom: 10px;
}

.scope-module p {
  font-size: 0.9rem;
  color: #ecf0f1; /* 浅灰色，保持可读性 */
}

.scope-module:hover {
  transform: translateY(-5px) scale(1.02);
}

.about-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 30px;
}

.card {
  background: rgba(255, 255, 255, 0.1); /* 半透明白色，保持整体风格 */
  border-radius: 8px;
  padding: 20px;
  width: 250px;
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, background 0.3s;
}

.card:hover {
  transform: translateY(-10px) scale(1.05);
}

.card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.card p {
  font-size: 1rem;
  color: #bdc3c7; /* 浅灰色，保持层次感 */
}
</style>
