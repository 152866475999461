<template>
  <div id="app" class="app-container">
    <HeaderComponent />
    <transition
        name="fade"
        mode="out-in"
    >
      <router-view :key="$route.path" />
    </transition>
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'
import FooterComponent from './components/FooterComponent.vue'

export default {
  components: {
    HeaderComponent,
    FooterComponent
  }
}
</script>

<style>
.app-container {
  min-height: 100vh; /* 确保容器高度至少为视口高度 */
  background: linear-gradient(135deg, #2c3e50, #34495e); /* 与 about 和 team 页面的配色一致 */
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(10px);
}
</style>
