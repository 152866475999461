<template>
    <footer class="footer">
        <div class="footer-container">
            <div class="footer-logo">浙江盛世文脉科技有限公司</div>
            <div class="footer-copy">
                <p>&copy; 2024 浙江盛世文脉科技有限公司. 版权所有.</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent",
};
</script>

<style scoped>
.footer {
    background: #f8f9fa;
    /* 使用淡色背景 */
    color: #333;
    /* 深色文本 */
    text-align: left;
    /* 左对齐文本 */
    padding: 20px 0;
}

.footer-container {
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    /* 左右两侧对齐 */
    align-items: center;
    padding: 0 20px;
}

.footer-logo {
    font-size: 20px;
    font-weight: bold;
}

.footer-copy {
    margin-top: 10px;
    font-size: 14px;
}
</style>