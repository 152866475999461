<template>
  <section class="team-section">
    <div class="container">
      <h2 class="team-title">我们的团队</h2>
      <p class="team-description">
        我们的团队由一群充满激情和专业知识的成员组成，他们致力于推动文物数字化的研究和实践。以下是我们团队的主要研究方向和成就：
      </p>
      <div class="lab-section">
        <h3 class="lab-title">艺术与考古图像数据实验室</h3>
        <p class="lab-introduction">
          我们的艺术与考古图像数据实验室致力于通过先进的数字技术和数据分析，促进文物的保护、研究和传播。我们专注于数字考古、三维建模以及图像处理，旨在为文化遗产的数字化提供创新的解决方案。
        </p>
      </div>
      <div class="team-members">
        <div class="team-member" v-for="(member, index) in teamMembers" :key="index">
          <h3>{{ member.name }}</h3>
          <p>{{ member.role }}</p>
          <p>{{ member.expertise }}</p>
          <div class="member-achievements">
            <h4>主要成就：</h4>
            <ul>
              <li v-for="(achievement, index) in member.achievements" :key="index">{{ achievement }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      teamMembers: [
        {
          name: "张伟",
          role: "首席研究员",
          expertise: "文物数字化技术和数据处理",
          achievements: [
            "开发高保真文物图像采集设备，荣获第七届全国十佳文博技术产品及服务奖。",
            "研发图像色彩校正管理软件，确保图像真实还原。"
          ]
        },
        {
          name: "李芳",
          role: "技术开发经理",
          expertise: "文化遗产环境监测与数据分析",
          achievements: [
            "参与数字考古报告的编制。",
            "研究丝绸世界地图数字化可视化技术。"
          ]
        },
        {
          name: "王强",
          role: "项目协调员",
          expertise: "团队项目管理与协调",
          achievements: [
            "推动文物数字化项目的顺利进行。",
            "协调团队内外部资源，提高项目效率。"
          ]
        },
      ],
    };
  },
};
</script>

<style scoped>
.team-section {
  padding: 80px 20px;
  background: linear-gradient(135deg, #2c3e50, #34495e); /* 深蓝色渐变背景，保持一致性 */
  color: #ecf0f1; /* 浅灰色字体，易读性强 */
  text-align: center;
}

.team-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #f39c12; /* 橙色标题，与关于我们页面一致 */
}

.team-description {
  font-size: 1.2rem;
  margin-bottom: 20px; /* 调整底部间距 */
  color: #bdc3c7; /* 浅灰色，保持视觉平衡 */
}

.lab-section {
  background: rgba(255, 255, 255, 0.1); /* 半透明白色背景 */
  border-radius: 8px;
  padding: 40px; /* 增加内边距 */
  margin-bottom: 40px; /* 底部间距 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 增加阴影效果 */
}

.lab-title {
  font-size: 2rem; /* 增大标题字体 */
  margin-bottom: 15px; /* 调整底部间距 */
  color: #f39c12; /* 橙色，与标题一致 */
}

.lab-introduction {
  font-size: 1.1rem; /* 增大字体 */
  color: #d1d1d1; /* 较浅的颜色 */
}

.team-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.team-member {
  flex: 1 1 300px;
  min-width: 250px;
  max-width: 350px;
  background: rgba(255, 255, 255, 0.1); /* 半透明白色背景，保持一致风格 */
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

.team-member h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #f39c12; /* 橙色，与标题一致 */
}

.team-member p {
  font-size: 1rem;
  color: #ecf0f1; /* 浅灰色，保持可读性 */
}

.member-achievements {
  margin-top: 15px;
  color: #bdc3c7; /* 浅灰色，保持层次感 */
}

.member-achievements h4 {
  font-weight: bold;
  color: #f39c12; /* 橙色，突出成就标题 */
}

.member-achievements ul {
  list-style-type: none;
  padding: 0;
}

.member-achievements li {
  margin-bottom: 5px;
}

.team-member:hover {
  transform: translateY(-5px) scale(1.02);
}
</style>
